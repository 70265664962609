export enum ForecastType {
  Weighted = 'Weighted',
  Lagged = 'Lagged',
  Unweighted = 'Unweighted',
}

export enum ForecastStatusType {
  Pending = 'Pending',
  Created = 'Created',
  Error = 'Error',
  Closed = 'Closed',
}

export enum ForecastMethod {
  Linear = 'Linear',
  Logarithmic = 'Logarithmic',
}

export enum ImportType {
  Sales = 'Sales',
  Product = 'Product',
  Pricing = 'Pricing',
}

export enum UserRole {
  Admin = 'Administrator',
  SalesBonusAdmin = 'Sales Bonus Administrator',
  ForecastingUser = 'Forecasting User',
  SalesBonusUser = 'Sales Bonus User',
  PricingAdmin = 'Pricing Administrator',
  ProductUser = 'Product User',
}

export interface CreateForecastRequest {
  name: string;
  method: ForecastMethod;
  type: ForecastType;
  startDate: Date | string;
  endDate: Date | string;
  lookbackStartDate: Date | string;
  lookbackEndDate: Date | string;
  calculationPeriod: string | undefined;
  weights: any;
  status: string;
}

export interface UpdateForecastRequest {
  forecastId: string;
  name: string;
  method: ForecastMethod;
  type: ForecastType;
  startDate: Date | string;
  endDate: Date | string;
  lookbackStartDate: Date | string;
  lookbackEndDate: Date | string;
  calculationPeriod: string | undefined;
  status: string;
  weights: any;
}

export interface GetForecastRequest {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  sortOrder?: string;
  allForecast?: boolean;
}

export interface GetForecastBudgetRequest {
  page: number;
  pageSize: number;
  forecastId: string;
  forecastCalendarId: string;
  search?: string;
  territoryId?: string;
  sort?: string;
  sortOrder?: string;
  isExec?: boolean;
  isProjected?: boolean;
}

export interface GetPrevAndCurrentForecastBudgetRequest {
  forecastId: string;
  forecastCalendarId: string;
  currentForecastYear: number;
}

interface UpdateForecastBudgetData {
  forecastBudgetId: string;
  salesGrowth: number;
  priceGrowth: number;
  quantity?: number;
}

export interface UpdateForecastBudgetRequest {
  territoryId: string | null;
  forecastId: string;
  isExec: boolean;
  isProduct?: boolean;
  data: UpdateForecastBudgetData[];
}

export interface UpdateForecastProductRequest {
  forecastProductId: string;
  salesGrowth: string;
  priceGrowth: string;
  quantity: string;
  territoryId?: string;
  revenue: string;
}

export interface GetForecastProductRequest {
  page: number;
  pageSize: number;
  forecastId: string;
  search?: string;
  territoryId?: string;
  sort?: string;
  sortOrder?: string;
  forecastCalendarId: string;
  brandId: string | undefined;
}

export interface GetForecastBestProductsRequest {
  page: number;
  forecastId: string;
  search?: string;
  territoryId?: string;
  sort?: string;
  sortOrder?: string;
  currentForecastYear: number;
  forecastCalendarId: string;
}

export interface GetUsersRequest {
  page: number;
  pageSize: number;
  search?: string;
  sort?: string;
  sortOrder?: string;
}

export interface TaskRequest {
  taskId: string;
}

export interface ListHistoryRequest {
  forecastId: string;
}

export interface RollbackHistoryRequest {
  historyId: string;
}

export interface GetProjectionRequest {
  forecastId?: string;
  projectionId?: string;
  calendarId?: string;
  territoryId?: string;
}

export interface GetProjectionsRequest {
  forecastId: string;
  currentForecastYear: number;
  territoryId: string;
}

export interface DiscardProjectionRequest {
  projectionId: string;
}

export interface CommitProjectionRequest {
  projectionId: string;
  notes?: string;
}

export interface UpdateForecastBudgetProjectionRequest {
  forecastId?: string;
  projectionId?: string;
  territoryId: string | null;
  isExec?: boolean;
  data: UpdateForecastBudgetData[];
}

export interface DiscardForecastBudgetProjectionLineRequest {
  projectionId?: string;
  data: any[];
}

export interface ITerritory {
  name?: string;
  shortName: string;
  type: string;
  territoryId: string;
}

export interface IRole {
  name: UserRole;
  roleId: string;
}

export interface IUserData {
  userId?: string;
  firstName: string;
  lastName?: string;
  logoUrl?: string;
  isActive: boolean;
  email: string;
  roles?: any[];
  roleIds?: any[] | undefined;
  manager?: any;
  managerId?: number | undefined;
  territoryIds?: string[] | undefined;
  territories?: any[] | undefined;
}

export interface GetForecastReportRequest {
  forecastId: string;
  forecastCalendarId: string;
}

export interface ISalesFilterType {
  brandId: string;
  territoryId: string;
  toDate: string;
  fromDate: string;
}

export interface IForecast {
  name: string;
  forecastId: string;
  forecastCalendars: IForecastCalendars[];
  calculationPeriod: number;
  endDate: string;
  status: string;
  lookback: number;
  methodAndType: string;
  startDate: string;
}

export interface IForecastCalendars {
  createdAt: string;
  endDate: string;
  forecastCalendarId: string;
  forecastId: string;
  forecastWeight: IForecastWeights;
  periodType: string;
  startDate: string;
  updatedAt: string;
}

export interface IForecastWeights {
  forecastWeightId: string;
  forecastId: string;
  forecastCalendarId: string;
  timePeriod: number;
  weight: string;
}

export interface IBrandFamily {
  brandFamilyId: string;
  name: string;
  isDefault: boolean;
}
export interface IBrandData {
  brandId: string;
  brandName: string;
  isActive: boolean;
  brandFamilies: IBrandFamily[];
  brandFamilyName: string;
}

export interface GetBrandsRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  sortOrder?: string;
}

export interface ITerritoryOption {
  key: string;
  label: string;
  value: string;
  territoryId: string;
  children?: ITerritoryOption[];
}
export interface GetRegionalForecastRequest {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  sortOrder?: string;
  forecastId: string;
  forecastCalendarId: string;
  territoryType: string;
  referenceType: string;
  projectionFilter?: string;
}
