import moment from 'moment';

export const formatDate = (options: Intl.DateTimeFormatOptions, date?: Date) => {
  if (date) {
    return new Intl.DateTimeFormat('en-US', {
      ...options,
      timeZone: 'UTC',
    }).format(new Date(date));
  }
};

export const formatQuarter = (date: Date) => {
  return moment(date).format('YYYY [Q]Q');
};
