import React, { useEffect, useState } from 'react';
import BrandForecastTable from './BrandForecastTable';
import ForecastCheckModal from '../../components/ForecastCheckModal';
import { ProjectionProvider, useProjection } from '../../providers/ProjectionProvider';
import useForecastModal from '../../hooks/useForecastModal';
import { ForecastStatusWatcher } from '../../hooks/useWatchForecastStatus';
import { useForecast } from '../../providers/ForecastProvider';
import ActiveProjectionWidget from '../../components/projection/ActiveProjectionWidget';
import ForecastInfoCard from '../../components/forecastInfoCard';
import ForecastService from '../../services/forecast.service';
import useErrorHandling from '../../hooks/useErrorHandling';
import { BrandForecastProvider } from '../../providers/BrandForecastProvider';
import { useSearchParams } from 'react-router-dom';

const BrandForecastView: React.FC = () => {
  const { hasForecasts, forecastId, territoryId, forecastCalendarId, currentForecastYear, isForecastFromParams } =
    useForecast();
  const { setError } = useErrorHandling();
  const { showForecastModal, setShowForecastModal } = useForecastModal();
  const [isFetching, $isFetching] = useState<boolean>(true);
  const [forecastBudgetData, setForecastBudgetData] = useState({
    cases: 0,
    prevcases: 0,
    revenue: 0,
    prevrevenue: 0,
    casesGrowthForPrevYear: 0,
    revenueGrowthForPrevYear: 0,
  });
  const projectionContext = useProjection();
  const [projection, setProjection] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const paramProjectionId = searchParams.get('projectionId');

  useEffect(() => {
    const localStorageCalendarId = localStorage.getItem('TimePeriod') || '';
    const calendarId =
      forecastCalendarId && forecastCalendarId !== 'undefined' ? forecastCalendarId : localStorageCalendarId;
    if (forecastId && calendarId && currentForecastYear) {
      $isFetching(true);
      ForecastService.GetPrevAndCurrentForecastBudget({
        forecastId,
        forecastCalendarId: calendarId,
        currentForecastYear,
      })
        .then(async ({ data }) => {
          if (paramProjectionId) {
            await projectionContext.handlers.fetchByProjectionId(paramProjectionId);
          } else {
            await projectionContext.handlers.fetch(forecastId, {
              forecastCalendarId: calendarId,
              territoryId,
              checkUser: true,
            });
          }
          const projection = projectionContext.getProjection();
          setProjection(projection);
          setForecastBudgetData(data.forecastData);
          $isFetching(false);
        })
        .catch((error) => {
          $isFetching(false);
          setError({ status: error?.response?.status || 500 });
        });
    }

    return () => {
      setProjection(null);
    };
  }, [forecastId, forecastCalendarId, currentForecastYear, paramProjectionId]);

  const blurredStyle = {
    filter: isFetching ? 'blur(5px)' : 'none',
    transition: 'filter 0s ease',
  };

  return hasForecasts ? (
    <BrandForecastProvider>
      <ForecastCheckModal open={showForecastModal} setSOpen={setShowForecastModal} />
      <div className="d-flex justify-content-between">
        <div className="">
          <h3 className="text-dark mb-4">Brand Summary Forecast</h3>
        </div>
      </div>
      <div className="row">
        <div className="col" style={blurredStyle}>
          {isFetching && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '321px', width: '800px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <ForecastInfoCard forecastBudgetData={forecastBudgetData} currentForecastYear={currentForecastYear} />
        </div>
        <div
          className="col"
          style={{
            filter: projection === null ? 'blur(5px)' : 'none',
            transition: 'filter 0s ease',
          }}
        >
          {projection === null && (
            <div
              className="position-absolute d-flex align-items-center justify-content-center"
              style={{ height: '300px', width: '510px' }}
            >
              <div
                className="me-2 spinner-border text-info"
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
              ></div>
            </div>
          )}
          <ActiveProjectionWidget
            header={
              <div className="d-flex justify-content-between">
                <p className="text-primary m-0 fw-bold">{projection ? 'Active Projection' : 'No Active Projection'}</p>
              </div>
            }
            projection={projection}
          />
        </div>
      </div>
      <BrandForecastTable row scope="Management" />
      <BrandForecastTable row scope="Regional" setForecastBudgetData={setForecastBudgetData} />
    </BrandForecastProvider>
  ) : (
    <h3 className="text-dark mb-4">No Forecasts In the System</h3>
  );
};

const BrandForecast = () => {
  return (
    <ForecastStatusWatcher>
      <ProjectionProvider>
        <BrandForecastView />
      </ProjectionProvider>
    </ForecastStatusWatcher>
  );
};

export default BrandForecast;
