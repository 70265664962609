import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTable, useSortBy, useExpanded, useGlobalFilter } from 'react-table';
import { Table } from 'react-bootstrap';
import useForecastCalculator from '../../hooks/useForecastCalculator';
import ForecastService from '../../services/forecast.service';
import { useForecast } from '../../providers/ForecastProvider';
import { useProjection } from '../../providers/ProjectionProvider';
import {
  calculateRevenueGrowth,
  calculateSalesGrowth,
  sumForecastBudgetByTerritory,
  findParentTerrioryByType,
  calcTotalGrowthFromArray,
} from '../../utils/calculation';
import { formatNegativeNumFromStr, formatNum, formatNumOneDecimal } from '../../utils/formatNum';
import { hideLoading, showLoading } from '../../lib/uiService';
import { ConfigService } from '../../services/config.service';
import { validateOnChangeInput } from '../../utils/validation';
import StageButton from '../../components/projection/StageButton';
import DownloadSelector from '../../components/DownloadSelector';
import TableHead from '../../components/common/TableHead';
import useErrorHandling from '../../hooks/useErrorHandling';
import TableBody from '../../components/common/TableBody';
import CaseInput from '../../components/common/CaseInput';
import GrowthInput from '../../components/common/GrowthInput';
import { useBrandForecast } from '../../providers/BrandForecastProvider';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../providers/AuthProvider';
import Pagination from '../../components/Pagination';
import { UserRole } from '../../lib/types';

interface IYearSales {
  totalQuantity: number;
  totalRevenue: number;
}

export interface IBrandForecast {
  forecastBudgetId: string;
  brandName: string;
  brandId: string;
  currentQuantity: number;
  priorQuantity: number;
  currentYearSalesGrowth: number;
  priorYearSalesGrowth: number;
  year1T12: IYearSales;
  year1YTD: IYearSales;
  year1T12Growth: number;
  year1YTDGrowth: number;
  forecastQuantity: number;
  quantity?: number;
  salesGrowth: number;
  priceGrowth: number;
  currentRevenue: number;
  priorRevenue: number;
  forecastRevenue: number;
  revenueGrowth: number;
  execQuantity: number;
  execRevenue: number;
  salesDiffPercent?: number;
  revenueDiffPercent?: number;
}

interface IForecastBudget {
  forecastBudgetId: string;
  name: string;
  brandId: string;
  currentYearSales: number;
  priorYearSales: number;
  currentYearSalesGrowth: number;
  priorYearSalesGrowth: number;
  year1T12: IYearSales;
  year1YTD: IYearSales;
  year1T12Growth: number;
  year1YTDGrowth: number;
  quantity: number;
  salesGrowth: number;
  priceGrowth: number;
  currentYearRevenue: number;
  priorYearRevenue: number;
  revenue: number;
  execQuantity: number;
  execRevenue: number;
  explanation?: string;
}

interface IBrandForecastTable {
  scope: 'Management' | 'Regional';
  // had to define row prop-type to render cells in each column
  row: any;
  setForecastBudgetData?: any;
}

const config = ConfigService.GetConfig();
const mediumGrowthMin = config.growthMin;
const mediumGrowthMax = config.growthMax;
const lowBgColor = config.lowGrowthColor;
const mediumBgColor = config.mediumGrowthColor;
const highBgColor = config.highGrowthColor;

const BrandForecastTable: React.FC<IBrandForecastTable> = ({ scope, setForecastBudgetData }) => {
  const ref = React.useRef<any>({});
  const navigate = useNavigate();
  const { setError } = useErrorHandling();
  const {
    forecastId,
    territoryId,
    hasForecasts,
    forecastCalendarId,
    isForecastFromParams,
    currentForecastYear,
    isForecastClosed,
  } = useForecast();
  const { role } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [startPage, setStartPage] = useState<number>(1);
  const [currentStartPage, setCurrentStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [search] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [forecastedQuantity, setForecastedQuantity] = useState<number>(0);
  const [brandForecasts, setBrandForecasts] = useState<IBrandForecast[]>([]);
  const [regionTotals, setRegionTotals] = useState<any>({});
  const [divisionTotals, setDivisionTotals] = useState<any>({});
  const [nationalTotals, setNationalTotals] = useState<any>({});
  const [prevTerritoryTotals, setPrevTerritoryTotals] = useState<any>({});
  const [dataWasChanged, setDataWasChanged] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<IBrandForecast[]>([]);
  const [unfilteredData, setUnfilteredData] = useState<IBrandForecast[]>([]);
  const projectionContext = useProjection();
  const { regionalData, setRegionalData } = useBrandForecast();
  const [isFetching, $isFetching] = useState<boolean>(false);
  const { forecastCalculator, loadForecastCalculator } = useForecastCalculator();
  const [searchParams] = useSearchParams();
  const paramProjectionId = searchParams.get('projectionId');
  const paramUserId = searchParams.get('userId');
  const isExecTable = scope === 'Management';

  Object.assign(ref.current, { brandForecasts, initialData, regionalData, forecastCalculator });
  const baseQuantityRef = React.useRef<any>({});

  useEffect(() => {
    // make sure forecastCalendarId exist before loading
    const localStorageCalendarId = localStorage.getItem('TimePeriod') || '';
    const calendarId =
      forecastCalendarId && forecastCalendarId !== 'undefined' ? forecastCalendarId : localStorageCalendarId;
    if (hasForecasts && forecastId && calendarId && territoryId) {
      $isFetching(true);
      // load forecast calculator in background to prevent impact to UI
      setTimeout(() => {
        loadForecastCalculator(forecastId, calendarId, '', isExecTable);
      }, 1000 * 3);
      ForecastService.GetForecastBudget({
        page: 1,
        pageSize: Number.MAX_SAFE_INTEGER,
        forecastId: forecastId,
        territoryId: territoryId && territoryId !== 'undefined' ? territoryId : localStorage.getItem('Territory'),
        search,
        forecastCalendarId: calendarId,
        isExec: isExecTable,
        isProjected: true,
      })
        .then(async ({ data }) => {
          const totalForecastedQuantity = data.forecastBudgets.reduce(
            (total, item) => total + Number(item.quantity),
            0,
          );
          setForecastedQuantity(totalForecastedQuantity);
          if (!isExecTable) {
            const projection = projectionContext.getProjection();
            if (projection) {
              projection.applyToForecastData(data?.forecastBudgets);
            }
          }
          const dataToInsert = data.forecastBudgets?.map((forecastBudget: IForecastBudget) => {
            const {
              forecastBudgetId,
              name,
              brandId,
              currentYearSales,
              priorYearSales,
              currentYearSalesGrowth,
              priorYearSalesGrowth,
              quantity,
              salesGrowth,
              priceGrowth,
              currentYearRevenue,
              priorYearRevenue,
              revenue,
              year1T12,
              year1YTD,
              year1T12Growth,
              year1YTDGrowth,
              execQuantity,
              execRevenue,
              explanation,
            } = forecastBudget;
            baseQuantityRef.current[forecastBudgetId] = (Number(quantity) * 100) / (Number(salesGrowth) + 100);
            return {
              ...forecastBudget,
              forecastBudgetId,
              brandName: name,
              brandId,
              currentQuantity: currentYearSales,
              priorQuantity: priorYearSales,
              currentYearSalesGrowth,
              priorYearSalesGrowth,
              forecastQuantity: quantity,
              salesGrowth,
              priceGrowth,
              year1T12,
              year1YTD,
              year1T12Growth,
              year1YTDGrowth,
              currentRevenue: currentYearRevenue,
              priorRevenue: priorYearRevenue,
              forecastRevenue: revenue,
              revenueGrowth: calculateRevenueGrowth(salesGrowth, priceGrowth),
              execQuantity,
              execRevenue,
              explanation,
              salesDiffPercent: (quantity / execQuantity) * 100 - 100,
              revenueDiffPercent: (revenue / execRevenue) * 100 - 100,
            };
          });
          if (scope === 'Regional') setRegionalData(dataToInsert);
          setBrandForecasts(dataToInsert);
          setInitialData(dataToInsert);
          setUnfilteredData(dataToInsert);
          setCount(data.count);
          setRegionTotals(data?.territoriesHierearchySummed?.regionForecastBudgets);
          setDivisionTotals(data?.territoriesHierearchySummed?.divisionForecastBudgets);
          setNationalTotals(data?.territoriesHierearchySummed?.nationalForecastBudgets);
          setIsEditable(data.isEditable);
          setTotalPages(Math.ceil(data.count / pageSize));
        })
        .catch(({ response }) => {
          console.log(response);
          setError({ status: response.status || 500 });
        })
        .finally(() => {
          $isFetching(false);
        });
    }
  }, [forecastId, search, territoryId, forecastCalendarId, isForecastFromParams]);

  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    setPage(startPage);
  }, [startPage]);

  const updateBrandData = (prevData: any, id: string, updatedData: any) => {
    const prevItem = prevData.find((item: any) => item.forecastBudgetId === id);
    const baseQuantity = baseQuantityRef.current[id];
    if (updatedData.quantity !== undefined) {
      updatedData.quantity = Number(updatedData.quantity);
      if (updatedData.quantity === 0 || isNaN(updatedData.quantity)) return prevData;
    }
    if (updatedData.quantity !== undefined && baseQuantity && !prevItem.isZeroCase) {
      const salesGrowth = calculateSalesGrowth(updatedData.quantity, baseQuantity);
      updatedData.salesGrowth = salesGrowth;
    }
    if (Number(updatedData.salesGrowth) === -100) {
      return prevData;
    }
    if (!ref.current.forecastCalculator) return prevData;
    ref.current.forecastCalculator.forecastBudgetCollection.updateForecastBudgetGrowth({ ...prevItem, ...updatedData });
    ref.current.forecastCalculator.forecastBudgetCollection.applyTerritorySumQueue();
    const updatedItems = ref.current.forecastCalculator.getForecastBudgets();
    return prevData.map((row: any) => {
      const updatedItem = updatedItems.find((item: any) => item.forecastBudgetId === id);
      if (row.forecastBudgetId === id && updatedItem) {
        const newRow = {
          ...row,
          ...updatedData,
        };

        const newForecastRevenue = updatedItem.revenue;
        const newRevDiff = row.execRevenue ? (newForecastRevenue / row.execRevenue) * 100 - 100 : 0;
        const newRevenueGrowth = calculateRevenueGrowth(Number(newRow['salesGrowth']), Number(newRow['priceGrowth']));
        const newForecastQuantity = updatedItem.quantity;

        if (Object.keys(updatedData).includes('salesGrowth')) {
          if (scope === 'Regional') {
            setForecastBudgetData((prevData: any) => {
              return {
                ...prevData,
                cases: Number(prevData.cases) - row['forecastQuantity'] + newForecastQuantity,
                revenue: Number(prevData.revenue) - row['forecastRevenue'] + newForecastRevenue,
              };
            });
          }
          const newSalesDiff = row.execQuantity ? (newForecastQuantity / row.execQuantity) * 100 - 100 : 0;
          return {
            ...newRow,
            ...(scope === 'Management' && {
              execQuantity: newForecastQuantity,
              execRevenue: newForecastRevenue,
            }),
            forecastQuantity: newForecastQuantity,
            forecastRevenue: newForecastRevenue,
            revenueGrowth: newRevenueGrowth,
            salesDiffPercent: newSalesDiff,
            revenueDiffPercent: newRevDiff,
          };
        } else {
          if (scope === 'Regional') {
            setForecastBudgetData((prevData: any) => {
              return {
                ...prevData,
                revenue: Number(prevData.revenue) - row['forecastRevenue'] + newForecastRevenue,
              };
            });
          }
          return {
            ...newRow,
            ...(scope === 'Management' && {
              execRevenue: newForecastRevenue,
            }),
            forecastQuantity: newForecastQuantity,
            forecastRevenue: newForecastRevenue,
            revenueGrowth: newRevenueGrowth,
            revenueDiffPercent: newRevDiff,
          };
        }
      } else {
        return row;
      }
    });
  };

  const handleChange = (id: string, updatedData: any) => {
    if (scope === 'Regional') {
      setRegionalData((prevData: any) => {
        setUnfilteredData((prevData) => updateBrandData(prevData, id, updatedData));
        return updateBrandData(prevData, id, updatedData);
      });
    } else {
      setBrandForecasts((prevData) => {
        const updatedManagementData = updateBrandData(prevData, id, updatedData);
        const rowFromManagement = updatedManagementData.find((item: any) => id === item.forecastBudgetId);
        setRegionalData((prevData: any) => {
          return prevData.map((row: any) => {
            if (row.brandId === rowFromManagement.brandId) {
              const newRevDiff =
                rowFromManagement.execRevenue !== 0
                  ? (row.forecastRevenue / rowFromManagement.execRevenue) * 100 - 100
                  : 0;
              if (Object.keys(updatedData).includes('salesGrowth')) {
                const newSalesDiff =
                  rowFromManagement.forecastQuantity !== 0
                    ? (row.forecastQuantity / rowFromManagement.forecastQuantity) * 100 - 100
                    : 0;

                return {
                  ...row,
                  execQuantity: rowFromManagement.forecastQuantity,
                  execRevenue: rowFromManagement.execRevenue,
                  salesDiffPercent: newSalesDiff,
                  revenueDiffPercent: newRevDiff,
                };
              } else {
                return {
                  ...row,
                  execRevenue: rowFromManagement.execRevenue,
                  revenueDiffPercent: newRevDiff,
                };
              }
            } else {
              return row;
            }
          });
        });
        setUnfilteredData((prevData) => updateBrandData(prevData, id, updatedData));
        return updatedManagementData;
      });
    }
    setDataWasChanged(true);
  };

  const getChangedRows = React.useCallback(() => {
    const changedRows =
      scope === 'Management'
        ? ref.current.brandForecasts.filter((brandForecast: any, index: number) => {
            return (
              brandForecast?.quantity !== initialData[index].quantity ||
              brandForecast?.salesGrowth !== initialData[index].salesGrowth ||
              brandForecast?.priceGrowth !== initialData[index].priceGrowth
            );
          })
        : ref.current.regionalData.filter((brandForecast: any, index: number) => {
            return (
              brandForecast?.quantity !== initialData[index].quantity ||
              brandForecast?.salesGrowth !== initialData[index].salesGrowth ||
              brandForecast?.priceGrowth !== initialData[index].priceGrowth
            );
          });
    return changedRows;
  }, [ref.current.brandForecasts, isFetching]);
  const handleSave = (changedData?: any) => {
    const changedRows = changedData ? changedData : getChangedRows();
    if (changedRows.length === 0) return;
    const data = changedRows.map(({ forecastBudgetId, salesGrowth, priceGrowth, quantity, explanation }: any) => ({
      forecastBudgetId,
      salesGrowth,
      priceGrowth,
      quantity,
      explanation,
    }));

    showLoading();
    if (isExecTable) {
      return ForecastService.UpdateForecastBudget({
        territoryId,
        forecastId,
        isExec: isExecTable,
        data,
      })
        .then(() => {
          hideLoading();
          setInitialData(scope === 'Management' ? brandForecasts : regionalData);
          setUnfilteredData(scope === 'Management' ? brandForecasts : regionalData);
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    } else {
      return projectionContext.handlers
        .update({
          territoryId,
          forecastId,
          isExec: false,
          data,
        })
        .then(() => {
          hideLoading();
          toast.success('Change Successfully Staged');
          setInitialData(scope === 'Regional' ? regionalData : brandForecasts);
          setUnfilteredData(scope === 'Regional' ? regionalData : brandForecasts);
        })
        .catch((error) => {
          hideLoading();
          console.log(error);
        });
    }
  };

  const showProductForecast = (brandId: string, brandName: string) => {
    navigate(
      isForecastFromParams
        ? `/forecast/product/${brandId}?forecastId=${forecastId}&projectionId=${paramProjectionId}&forecastCalendarId=${forecastCalendarId}&territoryId=${territoryId}&year=${currentForecastYear}&userId=${paramUserId}`
        : `/forecast/product/${brandId}`,
      {
        state: {
          brandName,
        },
      },
    );
  };

  const totalPriorYearSales = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.priorQuantity), 0);
  }, [initialData]);

  const totalPriorYearSalesGrowth = useMemo(() => {
    return calcTotalGrowthFromArray(
      initialData,
      (item) => item?.priorQuantity || 0,
      (item) => item?.priorYearSalesGrowth || 100,
    );
  }, [initialData]);

  const totalYear1T12 = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.year1T12.totalQuantity), 0);
  }, [initialData]);

  const totalYear1YTD = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.year1YTD.totalQuantity), 0);
  }, [initialData]);

  const totalBaseForecastSales = useMemo(() => {
    return initialData.reduce(
      (total, item) => total + (Number(item.forecastQuantity) * 100) / (100 + Number(item.salesGrowth)),
      0,
    );
  }, [initialData]);

  const totalExecCases = useMemo(() => {
    return scope === 'Management'
      ? brandForecasts.reduce((total, item) => total + Number(item.forecastQuantity), 0)
      : regionalData.reduce((total, item) => total + Number(item.execQuantity), 0);
  }, [brandForecasts, regionalData]);

  const totalForecastSales = useMemo(() => {
    return scope === 'Management'
      ? brandForecasts.reduce((total, item) => total + Number(item.forecastQuantity), 0)
      : regionalData.reduce((total, item) => total + Number(item.forecastQuantity), 0);
  }, [brandForecasts, regionalData]);

  const totalCurrentYearRevenue = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.currentRevenue), 0);
  }, [initialData]);

  const totalT12Growth = useMemo(() => {
    return calcTotalGrowthFromArray(
      initialData,
      (item) => item?.year1T12?.totalQuantity || 0,
      (item) => item?.year1T12Growth || 100,
    );
  }, [initialData]);

  const total1YTDGrowth = useMemo(() => {
    return calcTotalGrowthFromArray(
      initialData,
      (item) => item?.year1YTD?.totalQuantity || 0,
      (item) => item?.year1YTDGrowth || 100,
    );
  }, [initialData]);

  const totalBaseForecastRevenue = useMemo(() => {
    return initialData.reduce(
      (total, item) =>
        total + (Number(item.forecastRevenue) * 100) / (100 + formatNegativeNumFromStr(item.revenueGrowth)),
      0,
    );
  }, [initialData]);

  const totalForecastRevenue = useMemo(() => {
    return scope === 'Management'
      ? brandForecasts.reduce((total, item) => total + formatNegativeNumFromStr(item.forecastRevenue), 0)
      : regionalData.reduce((total, item) => total + formatNegativeNumFromStr(item.forecastRevenue), 0);
  }, [brandForecasts, regionalData]);

  const totalPriceGrowth = useMemo(() => {
    const dataToTest = scope === 'Management' ? brandForecasts : regionalData;
    return dataToTest.length > 0
      ? dataToTest.reduce((total, item) => total + formatNegativeNumFromStr(item.priceGrowth), 0)
      : 0;
  }, [brandForecasts, regionalData]);

  const tableItemStyle = (
    scope === 'Regional'
      ? {
          backgroundColor: 'unset',
          '--bs-table-accent-bg': 'unset',
          color: 'rgb(41 48 66)',
        }
      : {}
  ) as React.CSSProperties;

  function getClass(scope: any, diffPercent: number) {
    return scope === 'Regional'
      ? `${
          (diffPercent || 0) < Number(mediumGrowthMin)
            ? lowBgColor
            : (diffPercent || 0) > Number(mediumGrowthMax)
              ? highBgColor
              : mediumBgColor
        } text-dark align-middle`
      : '';
  }

  const totalProjectedQuantity = useMemo(() => {
    return initialData.reduce((total, item) => total + Number(item.quantity), 0);
  }, [initialData]);

  const totalSalesGrowthPercentage = useMemo(() => {
    return ((totalProjectedQuantity - forecastedQuantity) / forecastedQuantity) * 100;
  }, [brandForecasts, regionalData]);

  const totalSalesGrowthClass =
    scope === 'Regional'
      ? `${
          totalSalesGrowthPercentage < Number(mediumGrowthMin)
            ? lowBgColor
            : totalSalesGrowthPercentage > Number(mediumGrowthMax)
              ? highBgColor
              : mediumBgColor
        } text-dark`
      : '';

  const totalRevenueGrowthPercentage = totalCurrentYearRevenue
    ? (totalForecastRevenue / totalBaseForecastRevenue - 1) * 100.0
    : 0;

  const totalRevenueGrowthClass =
    scope === 'Regional'
      ? `${
          totalRevenueGrowthPercentage < Number(mediumGrowthMin)
            ? lowBgColor
            : totalRevenueGrowthPercentage > Number(mediumGrowthMax)
              ? highBgColor
              : mediumBgColor
        } text-dark`
      : '';

  useEffect(() => {
    setPrevTerritoryTotals({
      totalSalesGrowthPercentage,
      totalRevenueGrowthPercentage,
      totalForecastRevenue,
      totalForecastSales,
      totalExecCases,
    });
  }, []);

  useEffect(() => {
    const updateTotals = (prevData: any, isExec: boolean) => {
      const adjustments: any = {
        execTotalQuantity: Number(prevData.execTotalQuantity) - prevTerritoryTotals.totalExecCases + totalExecCases,
        notExecTotalQuantity:
          Number(prevData.notExecTotalQuantity) - prevTerritoryTotals.totalForecastSales + totalForecastSales,
        notExecTotalRevenue:
          Number(prevData.notExecTotalRevenue) - prevTerritoryTotals.totalForecastRevenue + totalForecastRevenue,
        notExecTotalRevenueGrowth:
          Number(prevData.notExecTotalRevenueGrowth) -
          prevTerritoryTotals.totalRevenueGrowthPercentage +
          totalRevenueGrowthPercentage,
        notExecTotalSalesGrowth:
          Number(prevData.notExecTotalSalesGrowth) -
          prevTerritoryTotals.totalSalesGrowthPercentage +
          totalSalesGrowthPercentage,
      };

      if (!isExec) {
        adjustments.execTotalRevenue =
          Number(prevData.execTotalRevenue) - prevTerritoryTotals.totalForecastRevenue + totalForecastRevenue;
        adjustments.execTotalRevenueGrowth =
          Number(prevData.execTotalRevenueGrowth) -
          prevTerritoryTotals.totalRevenueGrowthPercentage +
          totalRevenueGrowthPercentage;
        adjustments.execTotalSalesGrowth =
          Number(prevData.execTotalSalesGrowth) -
          prevTerritoryTotals.totalSalesGrowthPercentage +
          totalSalesGrowthPercentage;
      }

      return { ...prevData, ...adjustments };
    };

    const updateAllTotals = (isExec: boolean) => {
      const updatedItems = ref.current.forecastCalculator.getForecastBudgets();
      if (regionTotals) {
        const regionTerritory = findParentTerrioryByType(ref.current.forecastCalculator, territoryId, 'Region');
        const regionForecastBudgetSum = sumForecastBudgetByTerritory(updatedItems, regionTerritory?.territoryId);
        setRegionTotals((prevData: any) => {
          const newData = {
            ...updateTotals(prevData, isExec),
            ...(!isExec
              ? {
                  execTotalQuantity: regionForecastBudgetSum.quantity,
                  execTotalRevenue: regionForecastBudgetSum.revenue,
                }
              : {
                  notExecTotalQuantity: regionForecastBudgetSum.quantity,
                  notExecTotalRevenue: regionForecastBudgetSum.revenue,
                }),
          };
          return newData;
        });
      }
      if (divisionTotals) {
        const divisionTerritory = findParentTerrioryByType(ref.current.forecastCalculator, territoryId, 'Division');
        const divisionForecastBudgetSum = sumForecastBudgetByTerritory(updatedItems, divisionTerritory?.territoryId);

        setDivisionTotals((prevData: any) => {
          const newData = {
            ...updateTotals(prevData, isExec),
            ...(!isExec
              ? {
                  execTotalQuantity: divisionForecastBudgetSum.quantity,
                  execTotalRevenue: divisionForecastBudgetSum.revenue,
                }
              : {
                  notExecTotalQuantity: divisionForecastBudgetSum.quantity,
                  notExecTotalRevenue: divisionForecastBudgetSum.revenue,
                }),
          };
          return newData;
        });
      }
      if (nationalTotals) {
        const nationalTerritory = findParentTerrioryByType(ref.current.forecastCalculator, territoryId, 'National');
        const nationalForecastBudgetSum = sumForecastBudgetByTerritory(updatedItems, nationalTerritory?.territoryId);
        setNationalTotals((prevData: any) => {
          const newData = {
            ...updateTotals(prevData, isExec),
            ...(!isExec
              ? {
                  execTotalQuantity: nationalForecastBudgetSum.quantity,
                  execTotalRevenue: nationalForecastBudgetSum.revenue,
                }
              : {
                  notExecTotalQuantity: nationalForecastBudgetSum.quantity,
                  notExecTotalRevenue: nationalForecastBudgetSum.revenue,
                }),
          };
          return newData;
        });
      }
    };

    if (dataWasChanged) {
      if (scope === 'Regional') {
        ref.current.forecastCalculator && updateAllTotals(true);
      } else {
        ref.current.forecastCalculator && updateAllTotals(false);
      }

      setPrevTerritoryTotals({
        totalSalesGrowthPercentage,
        totalRevenueGrowthPercentage,
        totalForecastRevenue,
        totalForecastSales,
        totalExecCases,
      });
      setDataWasChanged(false);
    }
  }, [dataWasChanged]);

  let canEditForecast = false;
  if (scope === 'Management') {
    canEditForecast = isEditable && !isForecastClosed && role.includes(UserRole.Admin);
  } else if (scope === 'Regional') {
    canEditForecast = isEditable && !isForecastClosed && !isForecastFromParams;
  }

  // values set to any (to not cause errors) because libary's types are not compatible
  const columns: any = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'brandName',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return scope === 'Management' ? (
            forecastData.brandName
          ) : (
            <a
              style={{ textDecoration: 'underline' }}
              className="link-opacity-50-hover text-dark"
              onClick={() => showProductForecast(forecastData.brandId, forecastData.brandName)}
            >
              {forecastData.brandName}
            </a>
          );
        },
      },
      {
        accessor: 'priorQuantity',
        Header: () => (
          <>
            {Number(currentForecastYear) - 2}
            <br />
            Shipments
          </>
        ),
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <div className="d-flex flex-column align-items-center">
              <p className="m-0">{formatNum(forecastData.priorQuantity, 1, 1)}</p>
              <p className="m-0" style={{ fontSize: '11px' }}>
                {formatNum(forecastData.priorYearSalesGrowth, 1, 1)}%
              </p>
            </div>
          );
        },
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.priorQuantity;
          const valueB = rowB.original.priorQuantity;
          return valueA - valueB;
        },
      },
      {
        id: 'year1T12',
        Header: `${Number(currentForecastYear) - 1} T12`,
        accesor: 'year1T12',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <div className="d-flex flex-column align-items-center">
              <p className="m-0">{formatNum(forecastData.year1T12.totalQuantity, 1, 1)}</p>
              <p className="m-0" style={{ fontSize: '11px' }}>
                {formatNum(forecastData.year1T12Growth, 1, 1)}%
              </p>
            </div>
          );
        },
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.year1T12.totalQuantity;
          const valueB = rowB.original.year1T12.totalQuantity;
          return valueA - valueB;
        },
      },
      {
        id: 'year1YTD',
        Header: `${Number(currentForecastYear) - 1} YTD`,
        accesor: 'year1YTD',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <div className="d-flex flex-column align-items-center">
              <p className="m-0">{formatNum(forecastData.year1YTD.totalQuantity, 1, 1)}</p>
              <p className="m-0" style={{ fontSize: '11px' }}>
                {formatNum(forecastData.year1YTDGrowth, 1, 1)}%
              </p>
            </div>
          );
        },
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.year1YTD.totalQuantity;
          const valueB = rowB.original.year1YTD.totalQuantity;
          return valueA - valueB;
        },
      },
      {
        Header: () => (
          <>
            Current
            <br />
            Forecast
          </>
        ),
        accessor: 'forecastQuantity',
        canSort: true,
        Cell: ({ data, row }: { data: any; row: any; value: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle}>
              {canEditForecast ? (
                <CaseInput
                  style={{ width: '80px', textAlign: 'right' }}
                  value={formatNum(formatNegativeNumFromStr(forecastData.forecastQuantity), 0, 1)}
                  onChange={(e: any) => {
                    validateOnChangeInput(e, forecastData.forecastBudgetId, 'quantity', handleChange);
                  }}
                />
              ) : (
                <>{formatNum(forecastData.forecastQuantity, 0, 1)}</>
              )}
            </td>
          );
        },
      },
      {
        accessor: 'cases',
        Header: () =>
          scope === 'Management' ? (
            <>
              Mgmt
              <br />
              Growth
            </>
          ) : (
            <>
              RM
              <br />
              Growth
            </>
          ),
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle}>
              {canEditForecast ? (
                <GrowthInput
                  style={{ width: '45px' }}
                  value={forecastData.salesGrowth}
                  onChange={(e: any) => {
                    validateOnChangeInput(e, forecastData.forecastBudgetId, 'salesGrowth', handleChange);
                  }}
                />
              ) : (
                <>{formatNum(forecastData.salesGrowth)}</>
              )}
              &nbsp;%
            </td>
          );
        },
      },
      {
        accessor: 'price',
        Header: () =>
          scope === 'Management' ? (
            <>
              Mgmt
              <br />
              Price Growth
            </>
          ) : (
            <>
              RM
              <br />
              Price Growth
            </>
          ),
        canSort: false,
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle}>
              {canEditForecast ? (
                <GrowthInput
                  style={{ width: '45px' }}
                  value={forecastData.priceGrowth}
                  onChange={(e: any) => {
                    validateOnChangeInput(e, forecastData.forecastBudgetId, 'priceGrowth', handleChange);
                  }}
                />
              ) : (
                <>{formatNum(forecastData.priceGrowth)}</>
              )}
              &nbsp;%
            </td>
          );
        },
      },
      {
        Header: () => (
          <>
            {Number(currentForecastYear) - 1} Forecast
            <br />
            Revenue
          </>
        ),
        accessor: 'currentRevenue',
        Cell: ({ value }: { value: any }) => {
          return `$${formatNum(value)}`;
        },
        canSort: true,
      },
      {
        Header: () => (
          <>
            Current Forecast
            <br />
            Revenue
          </>
        ),
        accessor: 'acc',
        canSort: true,
        sortType: (rowA: any, rowB: any) => {
          const valueA = rowA.original.forecastRevenue;
          const valueB = rowB.original.forecastRevenue;
          return valueA - valueB;
        },
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];
          return (
            <td style={tableItemStyle} className={getClass(scope, Number(forecastData.revenueDiffPercent))}>
              ${formatNum(forecastData.forecastRevenue)}
            </td>
          );
        },
      },
      {
        id: 'save',
        Header: () => (
          <>
            {scope === 'Management' ? 'Mgmt' : 'RM'}
            <br />
            Revenue Growth
          </>
        ),
        canSort: true,
        accessor: 'acc2',
        Cell: ({ data, row }: { data: any; row: any }) => {
          const forecastData = data[row.id];

          return (
            <td style={tableItemStyle} className="cursor-pointer">
              {formatNum(forecastData.revenueGrowth) === '-0.00'
                ? formatNum(0)
                : formatNum(forecastData.revenueGrowth, 1, 1)}
              %
            </td>
          );
        },
      },
      canEditForecast && !isExecTable
        ? {
            id: 'action',
            Header: '',
            disableSortBy: true,
            accessor: 'action',
            Cell: ({ row }: { row: any }) => {
              const changedRows = getChangedRows();
              const changedItem = changedRows.find(
                (item: any) => item.forecastBudgetId === row.original.forecastBudgetId,
              );
              if (!changedItem && !row.original.explanation) return null;
              return (
                <td style={tableItemStyle} className="cursor-pointer">
                  <StageButton
                    changedItem={row.original}
                    handleSave={() => {
                      handleSave([row.original]);
                    }}
                  />
                </td>
              );
            },
          }
        : null,
    ].filter(Boolean);
    const conditionalColumn = {
      accessor: 'execQuantity',
      Header: () => (
        <>
          Mgmt
          <br />
          Forecast
        </>
      ),
      canSort: true,
      Cell: ({ value }: { value: any }) => {
        return `${formatNum(value, 1, 1)}`;
      },
    };

    if (scope !== 'Management') {
      baseColumns.splice(1, 0, conditionalColumn);
    }
    return baseColumns;
  }, [isEditable, isForecastFromParams, isFetching, scope, isForecastClosed]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: useMemo(() => {
        if (scope === 'Regional') {
          return regionalData;
        } else {
          return brandForecasts;
        }
      }, [brandForecasts, regionalData, scope, isFetching]),
      autoResetSortBy: false,
    },
    useSortBy,
    useExpanded,
  );

  const filterForecastBudget = (prevData, value) => {
    return unfilteredData.filter((brand) => {
      return (
        brand.brandName.toLowerCase().includes(value) ||
        String(brand.year1T12.totalQuantity).includes(value) ||
        String(brand.priorQuantity).includes(value) ||
        String(brand.year1YTD.totalQuantity).includes(value) ||
        String(brand.forecastQuantity).includes(value) ||
        String(brand.salesGrowth).includes(value) ||
        String(brand.priceGrowth).includes(value) ||
        String(brand.priorRevenue).includes(value) ||
        String(brand.forecastRevenue).includes(value) ||
        String(brand.revenueGrowth).includes(value)
      );
    });
  };

  const downloadColumnHeaders = [
    'Name',
    `${Number(currentForecastYear) - 2} Shipments`,
    'Current Forecast',
    scope === 'Management' ? 'Mgmt Case Growth' : 'RM Case Growth',
    scope === 'Management' ? 'Mgmt Price Growth' : 'RM Price Growth',
    'Current Year Revenue',
    'Next Year Revenue',
    `${scope === 'Management' ? 'Mgmt' : 'RM'} Revenue Growth`,
  ];

  const row: any = [];
  const blurredStyle = {
    filter: isFetching ? 'blur(5px)' : 'none',
    transition: 'filter 0s ease',
  };
  return (
    <div className="card shadow" style={{ marginBottom: '44px' }}>
      {isFetching && (
        <div
          className="position-absolute d-flex align-items-center justify-content-center"
          style={{ height: '900px', width: '960px' }}
        >
          <div
            className="me-2 spinner-border text-info"
            style={{
              position: 'relative',
              zIndex: 1,
            }}
          ></div>
        </div>
      )}
      <div className="card-header py-3">
        <p className="text-primary m-0 fw-bold">
          {scope === 'Management' ? 'Management Forecast Breakdown' : 'Regional Manager Forecast Breakdown'}
        </p>
      </div>
      <div className="card-body" style={blurredStyle}>
        <div className="row">
          <div className="col-md-6 text-nowrap">
            <div className="dataTables_length" aria-controls="dataTable">
              <label className="form-label">
                Show&nbsp;
                <select
                  value={pageSize}
                  className="d-inline-block form-select form-select-sm"
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    setPage(1);
                    setCurrentStartPage(1);
                    setTotalPages(Math.ceil(count / Number(e.target.value)));
                    if (scope === 'Regional') {
                      setRegionalData(initialData.slice(0, Number(e.target.value)));
                    } else {
                      setBrandForecasts(initialData.slice(0, Number(e.target.value)));
                    }
                  }}
                >
                  <option value={Number.MAX_SAFE_INTEGER}>All</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                &nbsp;
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-end justify-content-md-end dataTables_filter">
              <label className="form-label d-flex justify-content-md-end">
                <DownloadSelector
                  row={row}
                  hasFullForecast={role?.includes(UserRole.Admin)}
                  fileName={`${scope} Forecast Breakdown`}
                  downloadColumnHeaders={downloadColumnHeaders}
                  prepareDataFunction={() => {
                    const dataToUse = scope === 'Management' ? brandForecasts : regionalData;
                    dataToUse.forEach((item) => {
                      row.push([
                        { v: item.brandName, t: 's' },
                        { v: formatNumOneDecimal(item.priorQuantity), t: 's' },
                        { v: formatNumOneDecimal(item.forecastQuantity), t: 's' },
                        { v: `${formatNumOneDecimal(item.salesGrowth)}%`, t: 's' },
                        { v: `${formatNumOneDecimal(item.priceGrowth)}%`, t: 's' },
                        { v: `$${formatNum(item.priorRevenue)}`, t: 's' },
                        { v: `${formatNumOneDecimal(item.forecastRevenue)}`, t: 's' },
                        { v: `$${formatNum(item.revenueGrowth)}`, t: 's' },
                      ]);
                    });
                  }}
                />
                &nbsp;
              </label>
              <label className="form-label">
                <input
                  type="search"
                  className="form-control form-control-sm"
                  aria-controls="dataTable"
                  placeholder="Search"
                  onChange={(e) => {
                    const value = e.target.value.toLowerCase();
                    if (scope === 'Regional') {
                      setRegionalData(
                        value === '' ? unfilteredData : (prevData) => filterForecastBudget(prevData, value),
                      );
                    } else {
                      setBrandForecasts(
                        value === '' ? unfilteredData : (prevData) => filterForecastBudget(prevData, value),
                      );
                    }
                  }}
                />
              </label>
            </div>
          </div>
        </div>
        <div className={scope !== 'Management' ? 'dont-alternate-row-color' : ''}>
          <Table striped className="table-sm" bordered {...getTableProps()}>
            <TableHead isSticky headerGroups={headerGroups} />
            <TableBody
              {...getTableBodyProps()}
              rows={rows}
              renderRow={(row: any, key: number) => {
                prepareRow(row);
                const forecastData = row.original;
                return (
                  <React.Fragment key={key}>
                    <tr
                      {...row.getRowProps()}
                      key={key}
                      className={`table-body-font-size ${getClass(scope, Number(forecastData.salesDiffPercent))}`}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={`${key}-${index}`}
                            style={{
                              border: 'none',
                              backgroundColor: scope === 'Management' && '#fff',
                            }}
                            className={'text-dark align-middle'}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              }}
            />
            <tfoot>
              <tr>
                <td>
                  <strong>Grand Total</strong>
                </td>
                {scope === 'Regional' && (
                  <td>
                    <strong>{formatNum(totalExecCases, 1, 1)}</strong>
                  </td>
                )}
                <td className="text-center">
                  <strong>{formatNum(totalPriorYearSales, 1, 1)}</strong>
                  <br />
                  <strong>{formatNum(totalPriorYearSalesGrowth, 1, 1)}%</strong>
                </td>
                <td className="text-center">
                  <strong>{formatNum(totalYear1T12, 1, 1)}</strong>
                  <br />
                  <strong>{formatNum(totalT12Growth, 1, 1)}%</strong>
                </td>
                <td className="text-center">
                  <strong>{formatNum(totalYear1YTD, 1, 1)}</strong>
                  <br />
                  <strong>{formatNum(total1YTDGrowth, 1, 1)}%</strong>
                </td>
                <td className={`${totalSalesGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    {formatNum(totalForecastSales, 1, 1)}
                  </strong>
                </td>
                <td className={`${totalSalesGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    {formatNum(totalSalesGrowthPercentage, 1, 1)}%
                  </strong>
                </td>
                <td>
                  <strong>
                    {formatNum(totalPriceGrowth) === '-0.00' ? formatNum(0) : formatNum(totalPriceGrowth, 1, 1)}%
                  </strong>
                </td>
                <td>
                  <strong>${formatNum(totalCurrentYearRevenue)}</strong>
                </td>
                <td className={`${totalRevenueGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    ${formatNum(totalForecastRevenue)}&nbsp;
                  </strong>
                </td>
                <td className={`${totalRevenueGrowthClass} cursor-pointer`}>
                  <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                    {formatNum(totalRevenueGrowthPercentage, 1, 1)}%
                  </strong>
                </td>
              </tr>
              {regionTotals && (
                <tr>
                  <td>
                    <strong>Grand Totals for Region</strong>
                  </td>
                  {scope === 'Regional' && (
                    <td>
                      <strong>{formatNum(regionTotals?.execTotalQuantity, 1, 1)}</strong>
                    </td>
                  )}
                  <td className="text-center">
                    <strong>
                      {formatNum(
                        scope === 'Management'
                          ? regionTotals?.execTotalSalesQuantity
                          : regionTotals?.notExecTotalSalesQuantity,
                        1,
                        1,
                      )}
                    </strong>
                    <br />
                    <strong>{formatNum(regionTotals.priorYearSalesGrowth, 1, 1)}%</strong>
                  </td>
                  <td className="text-center">
                    <strong>{formatNum(regionTotals?.execYear1T12, 1, 1)}</strong>
                    <br />
                    <strong>{formatNum(regionTotals?.year1T12Growth, 1, 1)}%</strong>
                  </td>
                  <td className="text-center">
                    <strong>{formatNum(regionTotals?.execYear1YTD, 1, 1)}</strong>
                    <br />
                    <strong>{formatNum(regionTotals?.year1YTDGrowth, 1, 1)}%</strong>
                  </td>
                  <td>
                    <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                      {formatNum(
                        scope === 'Management' ? regionTotals?.execTotalQuantity : regionTotals?.notExecTotalQuantity,
                        1,
                        1,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                      {formatNum(
                        scope === 'Management'
                          ? regionTotals?.execTotalSalesGrowth
                          : regionTotals?.notExecTotalSalesGrowth,
                        1,
                        1,
                      )}
                      %
                    </strong>
                  </td>
                  <td>
                    <strong></strong>
                  </td>
                  <td>
                    <strong>
                      $
                      {formatNum(
                        scope === 'Management'
                          ? regionTotals?.execTotalSalesRevenue
                          : regionTotals?.notExecTotalSalesRevenue,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      $
                      {formatNum(
                        scope === 'Management' ? regionTotals?.execTotalRevenue : regionTotals?.notExecTotalRevenue,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {formatNum(
                        scope === 'Management'
                          ? regionTotals?.execTotalRevenueGrowth
                          : regionTotals?.notExecTotalRevenueGrowth,
                      )}
                      %
                    </strong>
                  </td>
                </tr>
              )}
              {divisionTotals && (
                <tr>
                  <td>
                    <strong>Grand Totals for Division</strong>
                  </td>
                  {scope === 'Regional' && (
                    <td>
                      <strong>{formatNum(divisionTotals?.execTotalQuantity, 1, 1)}</strong>
                    </td>
                  )}
                  <td className="text-center">
                    <strong>
                      {formatNum(
                        scope === 'Management'
                          ? divisionTotals?.execTotalSalesQuantity
                          : divisionTotals?.notExecTotalSalesQuantity,
                        1,
                        1,
                      )}
                    </strong>
                    <br />
                    <strong>{formatNum(divisionTotals.priorYearSalesGrowth, 1, 1)}%</strong>
                  </td>
                  <td className="text-center">
                    <strong>{formatNum(divisionTotals?.execYear1T12, 1, 1)}</strong>
                    <br />
                    <strong>{formatNum(divisionTotals?.year1T12Growth, 1, 1)}%</strong>
                  </td>
                  <td className="text-center">
                    <strong>{formatNum(divisionTotals?.execYear1YTD, 1, 1)}</strong>
                    <br />
                    <strong>{formatNum(divisionTotals?.year1YTDGrowth, 1, 1)}%</strong>
                  </td>
                  <td>
                    <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                      {formatNum(
                        scope === 'Management'
                          ? divisionTotals?.execTotalQuantity
                          : divisionTotals?.notExecTotalQuantity,
                        1,
                        1,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                      {formatNum(
                        scope === 'Management'
                          ? divisionTotals?.execTotalSalesGrowth
                          : divisionTotals?.notExecTotalSalesGrowth,
                        1,
                        1,
                      )}
                      %
                    </strong>
                  </td>
                  <td>
                    <strong></strong>
                  </td>
                  <td>
                    <strong>
                      $
                      {formatNum(
                        scope === 'Management'
                          ? divisionTotals?.execTotalSalesRevenue
                          : divisionTotals?.notExecTotalSalesRevenue,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      $
                      {formatNum(
                        scope === 'Management' ? divisionTotals?.execTotalRevenue : divisionTotals?.notExecTotalRevenue,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {formatNum(
                        scope === 'Management'
                          ? divisionTotals?.execTotalRevenueGrowth
                          : divisionTotals?.notExecTotalRevenueGrowth,
                      )}
                      %
                    </strong>
                  </td>
                </tr>
              )}
              {nationalTotals && (
                <tr>
                  <td>
                    <strong>Grand Totals for National</strong>
                  </td>
                  {scope === 'Regional' && (
                    <td>
                      <strong>{formatNum(nationalTotals?.execTotalQuantity, 1, 1)}</strong>
                    </td>
                  )}
                  <td className="text-center">
                    <strong>
                      {formatNum(
                        scope === 'Management'
                          ? nationalTotals?.execTotalSalesQuantity
                          : nationalTotals?.notExecTotalSalesQuantity,
                        1,
                        1,
                      )}
                    </strong>
                    <br />
                    <strong>{formatNum(nationalTotals.priorYearSalesGrowth, 1, 1)}%</strong>
                  </td>
                  <td className="text-center">
                    <strong>{formatNum(nationalTotals?.execYear1T12, 1, 1)}</strong>
                    <br />
                    <strong>{formatNum(nationalTotals?.year1T12Growth, 1, 1)}%</strong>
                  </td>
                  <td className="text-center">
                    <strong>{formatNum(nationalTotals?.execYear1YTD, 1, 1)}</strong>
                    <br />
                    <strong>{formatNum(nationalTotals?.year1YTDGrowth, 1, 1)}%</strong>
                  </td>
                  <td>
                    <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                      {formatNum(
                        scope === 'Management'
                          ? nationalTotals?.execTotalQuantity
                          : nationalTotals?.notExecTotalQuantity,
                        1,
                        1,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong className={scope === 'Regional' ? 'text-dark' : ''}>
                      {formatNum(
                        scope === 'Management'
                          ? nationalTotals?.execTotalSalesGrowth
                          : nationalTotals?.notExecTotalSalesGrowth,
                        1,
                        1,
                      )}
                      %
                    </strong>
                  </td>
                  <td>
                    <strong></strong>
                  </td>
                  <td>
                    <strong>
                      $
                      {formatNum(
                        scope === 'Management'
                          ? nationalTotals?.execTotalSalesRevenue
                          : nationalTotals?.notExecTotalSalesRevenue,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      $
                      {formatNum(
                        scope === 'Management' ? nationalTotals?.execTotalRevenue : nationalTotals?.notExecTotalRevenue,
                      )}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {formatNum(
                        scope === 'Management'
                          ? nationalTotals?.execTotalRevenueGrowth
                          : nationalTotals?.notExecTotalRevenueGrowth,
                      )}
                      %
                    </strong>
                  </td>
                </tr>
              )}
            </tfoot>
          </Table>
        </div>
        <div className="row">
          <div className="col-md-6 align-self-center">
            <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
              Showing {pageSize > count ? count : pageSize} of {count}
            </p>
          </div>
          <div className="col-md-6 mb-2">
            <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                  <div
                    className="page-link"
                    aria-label="Previous"
                    onClick={() => {
                      if (page > 1) {
                        const newPage = page - 1;
                        if (page === currentStartPage) {
                          setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                        }
                        setPage(newPage);
                        const indexOfLastItem = newPage * pageSize;
                        const indexOfFirstItem = indexOfLastItem - pageSize;
                        if (scope === 'Regional') {
                          setRegionalData(initialData.slice(indexOfFirstItem, indexOfLastItem));
                        } else {
                          setBrandForecasts(initialData.slice(indexOfFirstItem, indexOfLastItem));
                        }
                      }
                    }}
                  >
                    <span aria-hidden="true">«</span>
                  </div>
                </li>
                <Pagination
                  currentStartPage={currentStartPage}
                  totalPages={totalPages}
                  startPage={startPage}
                  pageSize={pageSize}
                  count={count}
                  page={page}
                  setPage={setPage}
                  setData={setBrandForecasts}
                  initialData={initialData}
                  setCurrentStartPage={setCurrentStartPage}
                />
                <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                  <div
                    className="page-link"
                    aria-label="Next"
                    onClick={() => {
                      const newPage = page + 1;
                      setPage(newPage);
                      setCurrentStartPage(currentStartPage + 1);
                      const indexOfLastItem = newPage * pageSize;
                      const indexOfFirstItem = indexOfLastItem - pageSize;
                      if (scope === 'Regional') {
                        setRegionalData(initialData.slice(indexOfFirstItem, indexOfLastItem));
                      } else {
                        setBrandForecasts(initialData.slice(indexOfFirstItem, indexOfLastItem));
                      }
                    }}
                  >
                    <span aria-hidden="true">»</span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {canEditForecast && isExecTable && !isForecastClosed && (
          <div className="mb-2">
            <button className="btn btn-primary float-end" type="button" onClick={() => handleSave()}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BrandForecastTable;
