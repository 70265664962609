import React, { type PropsWithChildren, useState } from 'react';
import { useSearchParams, matchPath, useLocation } from 'react-router-dom';

import { createSafeContext } from './createSafeContext';

interface ForecastContext {
  forecastId: string;
  setForecastId: (x: string, logPrev?: boolean) => void;
  territoryId: string;
  setTerritoryId: (x: string, logPrev?: boolean) => void;
  forecastCalendarId: string;
  setForecastCalendarId: (x: string, logPrev?: boolean) => void;
  forecastUpdated: boolean;
  setForecastUpdated: (x: boolean) => void;
  hasForecasts: boolean;
  setHasForecast: (x: boolean) => void;
  isForecastClosed: boolean;
  setIsForecastClosed: (x: boolean) => void;
  showPeriods: boolean;
  setShowPeriods: (x: boolean) => void;
  isForecastFromParams: boolean;
  setIsForecastFromParams: (x: boolean) => void;
  isProjectionCreatedByUser: boolean;
  setisProjectionCreatedByUser: (x: boolean) => void;
  currentForecastYear: number;
  setCurrentForecastYear: (x: number) => void;
}

const [useForecast, Provider] = createSafeContext<ForecastContext>();

export { useForecast };

const useSearchParamState = (param: string, storageKey?: string): [string, (val: string) => void] => {
  const [searchParams, $searchParams] = useSearchParams();
  const [stateValue, $setForecastId] = useState<string>(() => {
    if (searchParams.has(param)) {
      return searchParams.get(param);
    }
    // load state from localStorage
    if (storageKey) {
      const storageVal = localStorage.getItem(storageKey);
      if (storageVal !== null) {
        return storageVal;
      }
    }
  });
  const location = useLocation();
  const { pathname } = location;
  const setter = React.useCallback(
    (val: string, logPrev?: boolean) => {
      if (stateValue !== val) {
        $setForecastId(val);
        // persist state to localStorage
        if (storageKey) {
          localStorage.setItem(storageKey, val);
          if (logPrev) {
            localStorage.setItem(`previous${storageKey}`, val);
          }
        }
      }
      if (searchParams.get(param) !== val && ['/forecast/brand', '/forecast/product'].includes(pathname)) {
        const params = Object.fromEntries(searchParams.entries());
        $searchParams({ ...params, [param]: val });
      }
    },
    [stateValue, searchParams.get(param), pathname],
  );
  return [stateValue, setter];
};

export const ForecastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const curYear = new Date().getFullYear();
  const [forecastId, setForecastId] = useSearchParamState('forecastId', 'Forecast');

  const [territoryId, setTerritoryId] = useSearchParamState('territoryId', 'Territory');

  const [forecastCalendarId, setForecastCalendarId] = useSearchParamState('forecastCalendarId', 'TimePeriod');

  const [forecastUpdated, setForecastUpdated] = useState<boolean>(true);
  const [hasForecasts, setHasForecast] = useState<boolean>(true);
  const [showPeriods, setShowPeriods] = useState(false);
  const [currentForecastYear, setCurrentForecastYear] = useState(curYear);

  const [isForecastFromParams, setIsForecastFromParams] = useState(false);
  const [isProjectionCreatedByUser, setisProjectionCreatedByUser] = useState(false);
  const [isForecastClosed, setIsForecastClosed] = useState(false);

  return (
    <Provider
      value={{
        forecastId,
        setForecastId,
        territoryId,
        setTerritoryId,
        forecastUpdated,
        setForecastUpdated,
        hasForecasts,
        setHasForecast,
        forecastCalendarId,
        setForecastCalendarId,
        showPeriods,
        setShowPeriods,
        currentForecastYear,
        setCurrentForecastYear,
        isForecastFromParams,
        setIsForecastFromParams,
        isProjectionCreatedByUser,
        setisProjectionCreatedByUser,
        isForecastClosed,
        setIsForecastClosed,
      }}
    >
      {children}
    </Provider>
  );
};
