import Axios from '../lib/axios';

const LoginCheck = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/login_check');
};

const GetRedirectingRoutes = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/redirect-routes');
};

const LogOut = () => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/logout');
};

const GetManagers = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/user/managers');
};

const GetManagedUsers = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/user/managedUsers');
};

const AuthService = {
  LoginCheck,
  LogOut,
  GetRedirectingRoutes,
  GetManagers,
  GetManagedUsers,
};

export default AuthService;
