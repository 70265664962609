import React, { useEffect, useState } from 'react';
import TreePicker from 'rsuite/TreePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';

import { useForecast } from '../../providers/ForecastProvider';
import ForecastService from '../../services/forecast.service';
import { buildTreeOptionsFromTerritories } from '../../utils/buildTreeOption';
import { ITerritoryOption } from '../../lib/types';

interface ITerritorySelect {
  placeholder: string;
  setSavedValues?: (x: any) => void;
  isModal?: boolean | undefined;
  isParamData?: boolean;
}

const TerritorySelect: React.FC<ITerritorySelect> = (props) => {
  const { placeholder, setSavedValues, isModal, isParamData } = props;
  const [selectedItem, setSelectedItem] = useState<string>('');
  const { setTerritoryId, setForecastUpdated, hasForecasts, territoryId } = useForecast();
  const [territories, setTerritories] = useState<ITerritoryOption[]>([]);

  useEffect(() => {
    ForecastService.GetTerritoriesHierarchy()
      .then(({ data }) => {
        const value = buildTreeOptionsFromTerritories(data);
        setTerritories(value);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const prevValue = localStorage.getItem('Territory');
    if (!territoryId) {
      if (prevValue && !isParamData) {
        setSelectedItem(prevValue);
        setTerritoryId(prevValue);
        if (isModal && setSavedValues) {
          setSavedValues((prevState: any) => {
            return { ...prevState, territoryId: prevValue };
          });
        }
      }
    } else {
      if (isParamData) {
        setSelectedItem(prevValue);
      } else {
        setSelectedItem(territoryId);
      }
      if (isModal && setSavedValues) {
        setSavedValues((prevState: any) => {
          return { ...prevState, territoryId: territoryId };
        });
      }
    }
  }, [territoryId, isParamData]);

  const handleChange = (newTerritoryId: any) => {
    if (!newTerritoryId) {
      return;
    } else {
      if (isModal && setSavedValues) {
        setSelectedItem(newTerritoryId);
        localStorage.setItem('Territory', newTerritoryId);
        localStorage.setItem('previousTerritory', newTerritoryId);
        setSavedValues((prevState: any) => {
          return { ...prevState, territoryId: newTerritoryId };
        });
      } else {
        setSelectedItem(newTerritoryId);
        setTerritoryId(newTerritoryId, true);
        setForecastUpdated(true);
      }
    }
  };

  return (
    <div>
      <TreePicker
        defaultExpandAll
        value={selectedItem}
        data={territories}
        style={{ width: 246 }}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={!hasForecasts}
        className="w-100"
        menuStyle={{ zIndex: 5000, height: '240px' }}
      />
    </div>
  );
};

export default TerritorySelect;
